// import axios from 'axios'
import React, { useState } from 'react'
import { PaystackButton } from 'react-paystack'
import { Link } from 'react-router-dom';
import Iconblack from '../Iconblack.png';
import { numberWithCommas } from '../helper/numberFormat';
import sadeyes from '../sadeyes.png';

const Form = ({ closeForm }) => {
    const [sub, setSub] = useState(false)
    const [region, setRegion] = useState('');
    const [loading, setLoading] = useState(false)

    const two = JSON.parse(localStorage.getItem('catrs'))
    // const [set, setSet] = useState(two)
    let price = JSON.parse(localStorage.getItem('price'))
    // add 1,500 to internal shipping prices
    // add 1.5% of price to the shipping
    // try to use useContext hook with usereducer to pass the cart from app js to navbar kini 
    // try to use useEffect hook to create the cart stuff once and avoid re render
    function reg() {
        if (region === 'Lagos Mainland - ₦3500') {
            localStorage.setItem('shipping', (3500))
        } else if (region === 'Lagos Island - ₦5000') {
            localStorage.setItem('shipping', (5000))
        } else if (region === 'Other Nigerian states - ₦7000') {
            localStorage.setItem('shipping', (7000))
        }
        // } else if (region === 'London - ₦15000') {
        //     localStorage.setItem('shipping', (15000))
        // } else if (region === 'Rest of the UK - ₦17500') {
        //     localStorage.setItem('shipping', (17500))
        // } else if (region === 'Western Europe - ₦20000') {
        //     localStorage.setItem('shipping', (20000))
        // } else if (region === 'West Africa - ₦16500') {
        //     localStorage.setItem('shipping', (16500))
        // } else if (region === 'Rest of Africa - ₦23500') {
        //     localStorage.setItem('shipping', (23500))
        // } else if (region === 'USA / Canada - ₦20500') {
        //     localStorage.setItem('shipping', (20500))
        // } else if (region === 'Middle East - ₦23700') {
        //     localStorage.setItem('shipping', (23700))
        // } else if (region === 'Far East/ Indonesia - ₦26000') {
        //     localStorage.setItem('shipping', (26000))
        // } else if (region === 'Australia / New Zealand - ₦26000') {
        //     localStorage.setItem('shipping', (26000))
        // } else if (region === 'Rest of the world - ₦29000') {
        //     localStorage.setItem('shipping', (29000))
        // }

    }
    const shippingfee = localStorage.getItem('shipping')
    const newprice = Number(price * 0.015)
    const newshippingfee = Number(shippingfee) + newprice
    const vat = Math.round(Number(((price + newshippingfee) * 0.035)))
    const total = vat + newshippingfee + price


    const publicKey = process.env.REACT_APP_API_URL
    const amount = Number(total * 100).toFixed() // set in kobo!
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [phone, setPhone] = useState('');
    const [location, setLocation] = useState('');
    const [city, setCity] = useState('');
    const [states, setStates] = useState('');

    const pay = JSON.parse(localStorage.getItem('catrs'))
    const handleSubmit = async (e) => {
        setLoading(true)

        const formData = {
            name,
            email,
            phone,
            location,
            city,
            region,
            delivery: shippingfee,
            vat,
            states,
            amount: total,
            cart: pay
        }

        try {
            // const res = await fetch('http://localhost:5000/send-email', {
            const res = await fetch('https://dummybe-production.up.railway.app/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const result = await res.json();
            if (res.ok) {
                setLoading(false)
                alert("Thanks for doing business with us! Come back soon!!")
                localStorage.setItem('catrs', JSON.stringify([]))
                window.location.reload(true)
                // console.error('Email sent successfully!');
            } else {
                console.error(result.error || 'Error sending email.');
            }
        } catch (error) {
            console.error('Error:', error);
            console.error('Failed to send email.');
        } finally {
            setLoading(false)
        }
    };

    const componentProps = {
        email,
        amount,
        phone,
        currency: 'NGN',
        "metadata": {
            "custom_fields": [
                {
                    "display_name": "Full Name",
                    "variable_name": "full_name",
                    "value": name
                },
                {
                    "display_name": "Phone Number",
                    "variable_name": "phone_number",
                    "value": phone
                },
                {
                    "display_name": "Cart Items",
                    "variable_name": "Cart_items",
                    "value": pay.map(item => `Product Name: ${item.name}, Product Quantity: ${item.qty}, Product Size: ${item.size}`)
                },
                {
                    "display_name": "Region",
                    "variable_name": "region",
                    "value": region
                },
                {
                    "display_name": "Street Adresss",
                    "variable_name": "street_address",
                    "value": location
                },
                {
                    "display_name": "City",
                    "variable_name": "City",
                    "value": city
                },
                {
                    "display_name": "State",
                    "variable_name": "State",
                    "value": states
                },
            ]
        },
        publicKey,
        text: "Pay Now",
        onSuccess: () => { handleSubmit() },
        onClose: () => {
        },
    }

    const emptycart = (event) => {
        (event).preventDefault();
        reg()
        setSub(true)
        window.scrollTo(0, 0)
        // const content = {
        //     name: name,
        //     email: email,
        //     number: phone,
        //     region: region,
        //     location: location,
        //     cart: two,
        //     city: city,
        //     state: states
        // }

        // console.log(content);
        // axios.post("https://dnrskate.herokuapp.com/checkout", { content }).then(res => {
        // })
    }

    return (
        <div className='customer'>
            {loading ?
                <div className='loader'>
                    <div>
                        <img className='pulse' style={{ width: '100%', margin: "0 0 20px" }} src={sadeyes} alt="sadeyes"></img>
                        <h1 >Processing Order</h1>
                    </div>
                </div>
                : <>
                    {!sub && <div>

                        <p onClick={() => closeForm(false)} className="linkback lone two">&#8592; Back to Cart</p>
                        <form id="contact-form" onSubmit={emptycart}>


                            <fieldset id="test">

                                <h2>Customer Details</h2>

                                <div class="input">
                                    <input id="fullname" onChange={(e) => setName(e.target.value)} type="text" placeholder="Full Name" required autoFocus />
                                </div>

                                <div class="input">
                                    <input id="email" type="text" onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" required />
                                </div>

                                <div class="input">
                                    <input id="number" onChange={(e) => setPhone(e.target.value)} type="number" placeholder="Phone Number" required />
                                </div>
                                <div class="inputs">
                                    <select onChange={(e) => setRegion(e.target.value)} className="formselect" required>
                                        <option value="">selct Region</option>
                                        <option>Lagos Mainland - ₦3500</option>
                                        <option>Lagos Island - ₦5000</option>
                                        <option>Other Nigerian states - ₦7000</option>
                                    </select>
                                    <img src={Iconblack} alt='icon' className='drop3' />
                                    {/* <input id="Location" onChange={(e) => setLocation(e.target.value)} type="text" placeholder="Street Address" required /> */}
                                </div>
                                <div class="input">
                                    <input id="Location" onChange={(e) => setLocation(e.target.value)} type="text" placeholder="Street Address" required />
                                </div>
                                <div class="input">
                                    <input id="city" onChange={(e) => setCity(e.target.value)} type="text" placeholder="City" />
                                </div>
                                <div class="input">
                                    <input id="state" onChange={(e) => setStates(e.target.value)} type="text" placeholder="State" required />
                                </div>
                                <button id="submitbtn" type="submit" value="submit">Continue to Shipping</button>
                            </fieldset>
                        </form>
                    </div>
                    }


                    {
                        sub && <div>
                            <p onClick={() => setSub(false)} style={{ color: 'white', textAlign: 'left', cursor: 'pointer' }} className='linkback lone two'> &#8592; Change
                                details</p>
                            <fieldset class="shipping">
                                <h1> Shipping Details</h1>
                                <div>
                                    <h3 style={{ textAlign: 'center', paddingBottom: '20px', color: 'white' }}>Your Order</h3>
                                    {
                                        two.map((set, index) => (
                                            <div key={set.id} className="cart two" style={{ borderBottom: '1px solid black', borderTop: '1px solid black' }}>
                                                <div>
                                                    <p style={{ paddingBottom: '8px', fontSize: '12px' }}>{set.name} x {set.qty}</p>
                                                    <Link to={`/product/${set.slug}`} onClick={() => window.scrollTo(0, 0)}>
                                                        <img src={set.image} className="smallstwo" alt="merch"></img></Link>
                                                </div>
                                                {
                                                    set.hassize === false ? (
                                                        <div style={{ textAlign: 'center', paddingLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className="cartdesc">

                                                            <p style={{ paddingTop: '20px', paddingBottom: '20px', fontSize: '12px' }}>Price: ₦ {numberWithCommas(set.price)}</p>
                                                        </div>) : (
                                                        <div style={{ textAlign: 'center', paddingLeft: '10px', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }} className="cartdesc">
                                                            <p style={{ paddingTop: '20px', paddingBottom: '20px', fontSize: '12px' }}>size: {set.size}</p>
                                                            <p style={{ paddingTop: '0px', paddingBottom: '20px', fontSize: '12px' }}>Price: ₦{numberWithCommas(set.price)}</p>
                                                        </div>
                                                    )
                                                }
                                            </div>)
                                        )}
                                </div>
                                <div className='shipping-price'>
                                    <div>
                                        <h2>SubTotal:</h2> <h2>₦{numberWithCommas(price)} </h2>
                                    </div>
                                    <div>
                                        <h2>Shipping: </h2> <h2> ₦{numberWithCommas(newshippingfee)}</h2>
                                    </div>
                                    <div>
                                        <h2>VAT: </h2> <h2> ₦{numberWithCommas(vat)} </h2>
                                    </div>
                                    <div style={{ paddingTop: '15px' }}>
                                        <h2>Total:</h2> <h2> ₦{numberWithCommas(total)}</h2>
                                    </div>
                                </div>
                                <div className='payorder'>
                                    <PaystackButton className="paystack-button" {...componentProps} />
                                </div>
                            </fieldset>
                        </div>
                    }
                </>}
        </div >
    )
}

export default Form