import Footer from '../Components/Footer';
import Navbartwo from '../Components/Navbartwo'
import React from 'react';
import data from '../data.js'
import { Link } from 'react-router-dom';
import Navbarthree from '../Components/Navbartwothree';
import { numberWithCommas } from "../helper/numberFormat.js"
import MetaTags from '../Components/Metatags.js';


export default function Homescreen({ cart }) {
    const title = "Shop";
    return <div className='ps'>
        <MetaTags title={title} />
        <Navbarthree cart={cart}></Navbarthree>
        <main className='roow'>
            {
                data.products.map((product) => (
                    <div key={product._id} product={product}>
                        <Link to={`/product/${product.slug}`} onClick={() => window.scrollTo(0, 0)}>
                            <div className="col-4">
                                {product.imageadjustment ?  <img style={{width:'100px'}} src={product.image} alt={product.name} />
                                : <img src={product.image} alt={product.name} /> }
                                <h4>{product.name}</h4>
                                <p>N{numberWithCommas(product.price)}</p>
                            </div>
                        </Link>
                    </div>
                ))
            }
        </main>
        <Footer></Footer>
    </div>
}